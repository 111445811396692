import Layout from '@/views/layout/Layout';

export default [
  {
    path: '/sale',
    component: Layout,
    name: '销售计划',
    authority: 'sale',
    redirect: '/sale/sku-list',
    children: [
      {
        path: 'forecast-list',
        component: () => import('@/views/sale/forecast-list'),
        name: '销售预测',
        authority: 'forecast-list'
      },
      {
        path: 'sku-list',
        component: () => import('@/views/sale/sku-list'),
        name: '公司sku',
        authority: 'sku-list'
      },
      {
        path: 'edit-forecast',
        component: () => import('@/views/sale/edit-forecast'),
        name: '编辑销售预测数据',
        authority: 'edit-forecast'
      },
      {
        path: 'history-forecast',
        component: () => import('@/views/sale/history-forecast'),
        name: '历史销售预测数据',
        authority: 'history-forecast'
      },
      {
        path: 'sale-store-list',
        component: () => import('@/views/sale/sale-store-list'),
        name: '店铺信息',
        authority: 'sale-store-list'
      },
      {
        path: 'budget-list',
        component: () => import('@/views/budget/list'),
        name: '年度预算',
        authority: 'budget-list'
      },
      {
        path: 'budget-detail',
        component: () => import('@/views/budget/detail'),
        name: '年度预算详情',
        authority: 'budget-detail'
      },
      {
        path: 'budget-overview',
        component: () => import('@/views/budget/overview'),
        name: '预算总览',
        authority: 'budget-overview'
      },
      {
        path: 'spu-list',
        component: () => import('@/views/budget/spu'),
        name: 'SPU管理',
        authority: 'spu-list'
      },
      {
        path: 'logistics-cost',
        component: () => import('@/views/budget/cost'),
        name: '物流费用',
        authority: 'logistics-cost'
      },

      // 供应计划
      {
        path: 'supply-list',
        component: () => import('@/views/supply/list'),
        name: '供应计划预览',
        authority: 'supply-list'
      },
      {
        path: 'supply-detail',
        component: () => import('@/views/supply/detail'),
        name: '供应计划详情',
        authority: 'supply-detail'
      },
      {
        path: 'supply-plan',
        component: () => import('@/views/supply/plan'),
        name: '计划跟踪表',
        authority: 'supply-plan'
      },
      {
        path: 'supply-logistics',
        component: () => import('@/views/supply/logistics'),
        name: '物流跟踪表',
        authority: 'supply-logistics'
      },
      {
        path: 'supply-store',
        component: () => import('@/views/supply/store'),
        name: '仓库信息表',
        authority: 'supply-store'
      }
    ]
  }
];
