export default [
  {
    path: 'client-list',
    component: () => import('@/views/b2b/client-list'),
    name: '客户信息列表',
    authority: 'client-list'
  },
  {
    path: 'back-payment-list',
    component: () => import('@/views/b2b/back-payment-list'),
    name: '回款信息',
    authority: 'back-payment-list'
  },
  {
    path: 'payment-list',
    component: () => import('@/views/b2b/payment-list'),
    name: '付款申请',
    authority: 'payment-list'
  },
  {
    path: 'deliver-goods-list',
    component: () => import('@/views/b2b/deliver-goods-list'),
    name: '发货申请',
    authority: 'deliver-goods-list'
  },
  {
    path: 'period-statistics',
    component: () => import('@/views/b2b/period-statistics'),
    name: '数据看板',
    authority: 'period-statistics'
  },
  {
    path: 'period-detail',
    component: () => import('@/views/b2b/period-detail'),
    name: '期初期末',
    authority: 'period-detail'
  }
];
